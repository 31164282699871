var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"perm-manage"},[_c('div',{staticClass:"perm-manage-wrapper"},[_c('div',{staticClass:"perm-manage-header"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.addRole}},[_vm._v(" 新建角色 ")])],1),_c('div',{staticClass:"table-wrapper"},[_c('CTable',{ref:"cTable",attrs:{"tableColumns":_vm.tableColumns,"isEnableScrollWidth":true,"getList":_vm.getList},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var record = ref.record;
return [_c('span',{attrs:{"title":record.name}},[_vm._v(_vm._s(record.name))]),(record.id === _vm.SysAdmin.SYS)?_c('span',{staticClass:"list-label"},[_vm._v("默认")]):(record.id === _vm.SysAdmin.APP || record.id === _vm.SysAdmin.ORG)?_c('span',{staticClass:"list-label pre-mananger-label"},[_vm._v("预置")]):_vm._e()]}},{key:"users",fn:function(ref){
var record = ref.record;
return [_c('TableDeptUsers',{attrs:{"list":record.users}})]}},{key:"status",fn:function(ref){
var record = ref.record;
return [_c('div',{staticClass:"status",class:{'disable-close': record.id === _vm.SysAdmin.SYS}},[_c('div',{staticClass:"change-status ant-switch-small",on:{"click":function($event){return _vm.enableRole(record)}}}),_c('a-switch',{attrs:{"size":"small"},model:{value:(record.status),callback:function ($$v) {_vm.$set(record, "status", $$v)},expression:"record.status"}})],1)]}},{key:"action",fn:function(ref){
var record = ref.record;
return [(record.id !== _vm.SysAdmin.SYS)?_c('a-button',{staticClass:"operation-btn",attrs:{"type":"link","size":"small"},on:{"click":function($event){$event.stopPropagation();return _vm.editRole(record)}}},[_vm._v(" 编辑角色 ")]):_vm._e(),_c('a-button',{staticClass:"operation-btn",attrs:{"type":"link","size":"small"},on:{"click":function($event){$event.stopPropagation();return _vm.goPage(record)}}},[_vm._v(" 授权 ")]),(record.id === _vm.SysAdmin.APP || record.id === _vm.SysAdmin.ORG)?_c('a-button',{staticClass:"operation-btn",attrs:{"type":"link","size":"small"},on:{"click":function($event){$event.stopPropagation();return _vm.resetRole(record)}}},[_vm._v(" 重置 ")]):_vm._e(),_c('a-popconfirm',{attrs:{"title":"您确定删除该角色吗？","okText":"确定","cancelText":"取消","placement":"topLeft"},on:{"confirm":function($event){return _vm.delRole(record)}}},[(!_vm.sysAdmin.includes(record.id))?_c('a-button',{staticClass:"operation-btn",attrs:{"type":"link","size":"small"}},[_vm._v(" 删除 ")]):_vm._e()],1)]}}])})],1)]),_c('AddRole',{attrs:{"roleId":_vm.roleId,"operateType":_vm.operateType},on:{"reload":_vm.reload},model:{value:(_vm.isShowAddRole),callback:function ($$v) {_vm.isShowAddRole=$$v},expression:"isShowAddRole"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }