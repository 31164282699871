import { __decorate } from "tslib";
import { Component, Vue, Ref } from 'vue-property-decorator';
import { PermManageService } from '@/sevices/index';
import { OperateType, SysAdmin } from '../../../typings/perm-manage';
import { NodeType } from '../../../typings/organization/org-manage';
import { CTable } from '@cloudpivot-hermes/common-components';
let PermManage = class PermManage extends Vue {
    constructor() {
        super(...arguments);
        this.OperateType = OperateType;
        this.NodeType = NodeType;
        this.operateType = OperateType.Add;
        this.isShowAddRole = false;
        this.roleId = '';
        /**
         * table列定义
         */
        this.tableColumns = [
            {
                width: 60,
                dataIndex: 'index',
                key: 'index',
                align: 'center',
                fixed: 'left',
                slots: { title: 'indexTitle', name: '序号' },
                scopedSlots: { customRender: 'index' },
            },
            {
                dataIndex: 'name',
                key: 'name',
                ellipsis: true,
                width: 250,
                slots: { title: 'nameTitle', name: '角色名称' },
                scopedSlots: { customRender: 'name' },
            },
            {
                dataIndex: 'users',
                key: 'users',
                ellipsis: true,
                width: 400,
                slots: { title: 'usersTitle', name: '成员' },
                scopedSlots: { customRender: 'users' },
            },
            {
                dataIndex: 'description',
                key: 'description',
                ellipsis: true,
                slots: { title: 'descriptionTitle', name: '角色描述' },
            },
            {
                dataIndex: 'status',
                key: 'status',
                ellipsis: true,
                width: 150,
                slots: { title: 'statusTitle', name: '状态' },
                scopedSlots: { customRender: 'status' },
            },
            {
                width: 180,
                dataIndex: 'action',
                key: 'action',
                fixed: 'right',
                slots: { title: 'actionTitle', name: '操作' },
                scopedSlots: { customRender: 'action' },
            },
        ];
        this.SysAdmin = SysAdmin;
        this.sysAdmin = [
            SysAdmin.SYS,
            SysAdmin.APP,
            SysAdmin.ORG,
        ];
    }
    /**
     * 获取列表数据接口
     */
    async getList(params) {
        const res = await PermManageService.getRoleList(params);
        if (res.success) {
            res.data = res.data.map((item) => {
                return {
                    ...item,
                    users: item.users.map((user) => {
                        return {
                            ...user,
                            nodeType: NodeType.User,
                        };
                    }),
                    status: item.status === 'ENABLE',
                };
            });
        }
        return res;
    }
    addRole() {
        this.isShowAddRole = true;
        this.operateType = OperateType.Add;
        this.roleId = '';
    }
    editRole(record) {
        this.roleId = record.id;
        this.isShowAddRole = true;
        this.operateType = OperateType.Edit;
    }
    /**
     * 停用和启用
     */
    async enableRole(record) {
        const operateText = record.status ? '停用' : '启用';
        const status = record.status ? 'DISABLE' : 'ENABLE';
        const res = await PermManageService.enableRole({
            id: record.id,
            status: status,
        });
        if (res.success) {
            record.status = !record.status;
            this.$message.success(`角色已${operateText}`);
        }
    }
    /**
     * 删除角色
     */
    async delRole(record) {
        const res = await PermManageService.delRole({
            id: record.id,
        });
        if (res.success) {
            this.reload();
            this.$message.success('删除成功');
        }
    }
    /**
     * 重置角色
     */
    resetRole(record) {
        const resetRole = async () => {
            const res = await PermManageService.resetRole({
                id: record.id,
            });
            if (res.success) {
                this.reload();
                this.$message.success('重置成功');
            }
        };
        this.$confirm({
            title: '确定要重置组织管理员吗？',
            content: '重置后，该管理员角色的权限设置将恢复到初始状态',
            okText: '确定',
            cancelText: '取消',
            onOk: resetRole,
        });
    }
    goPage(record) {
        this.$router.push({
            name: 'auth-manager',
            query: {
                roleId: record.id,
            },
        });
    }
    /**
     * 重载
     */
    reload() {
        this.cTable.getTableList();
    }
};
__decorate([
    Ref()
], PermManage.prototype, "cTable", void 0);
PermManage = __decorate([
    Component({
        name: 'PermManage',
        components: {
            CTable,
            TableDeptUsers: () => import('@/components/sys-manage/perm-manage/table-dept-users.vue'),
            AddRole: () => import('@/components/sys-manage/perm-manage/add-role.vue'),
        },
    })
], PermManage);
export default PermManage;
