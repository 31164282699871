export var OperateType;
(function (OperateType) {
    OperateType[OperateType["Add"] = 0] = "Add";
    OperateType[OperateType["Edit"] = 1] = "Edit";
    OperateType[OperateType["Del"] = 2] = "Del";
})(OperateType || (OperateType = {}));
export var SysAdmin;
(function (SysAdmin) {
    SysAdmin["SYS"] = "sys000001";
    SysAdmin["APP"] = "app000002";
    SysAdmin["ORG"] = "org000003";
})(SysAdmin || (SysAdmin = {}));
